import { Modal, Spinner } from "@shopify/polaris";
import { Carousel, Spin } from "antd";
import React from "react";
import styled from "styled-components";
import { arrInvalid, objectInvalid } from "../../../helper";
import useToggle from "../../../hooks/useToggle";
import {
  SlideContainer,
  Container as ImageContainer,
} from "../../seller/ModalImageClaimPolaris";

const SlideNextArrow = React.lazy(() => import("../../shared/SlideNextArrow"));
const SlidePrevArrow = React.lazy(() => import("../../shared/SlidePrevArrow"));
const ViewOriginFile = React.lazy(() => import("../../shared/ViewOriginFile"));

const imgControl = {
  width: 90,
  height: 90,
  objectFit: "cover",
};

export default function MockupsPreview({
  files,
  width,
  maxWidth,
  maxHeight,
  height,
  hasShadow,
  isCompare,
  originImages,
}) {
  // Props
  const images = mergeImages(files?.files, originImages);
  const firstImage = images[0];
  const imgItemCls = `cursor-pointer block ${hasShadow ? "img-wrap" : ""}`;
  const imgItemStyle = {
    width: width || 60,
    height: height || 60,
    objectFit: "cover",
    maxWidth: maxWidth,
    maxHeight: maxHeight,
  };

  const [open, toggleOpen] = useToggle(false);
  const [url, setUrl] = React.useState(() => {
    if (!firstImage) return "";
    if (isCompare) return firstImage.url || firstImage.thumbnailUrl;
    return firstImage.thumbnailUrl || firstImage.url;
  });
  const [loaded, setLoaded] = React.useState(false);

  const handleImageChange = React.useCallback(
    (image) => () => {
      const url = getUrl(image);
      setUrl(url);
    },
    [],
  );
  const handleLoaded = React.useCallback(() => {
    setLoaded(true);
  }, []);

  if (arrInvalid(images)) return null;
  if (!firstImage) return null;

  return (
    <Wrapper>
      <div className="modal-image img-box-shadow">
        <img
          className={imgItemCls}
          src={url}
          alt={"product mockup"}
          key={firstImage.id}
          style={{
            ...imgItemStyle,
          }}
          onClick={toggleOpen}
          onLoad={handleLoaded}
        />
      </div>
      {loaded && (
        <div className="img-controls">
          {images.map((image, index) => {
            const src = getUrl(image);
            let cls = "cursor-pointer img-wrap";
            if (src === url) {
              cls += " active";
            }

            return (
              <img
                className={cls}
                style={imgControl}
                src={src}
                alt="product mockup control"
                key={`product-mockup-control-${index}`}
                onClick={handleImageChange(image)}
              />
            );
          })}
        </div>
      )}

      <ModalPreivew open={open} onClose={toggleOpen} images={images} />
    </Wrapper>
  );
}

function ModalPreivew({ open, onClose, images }) {
  const settings = React.useMemo(
    () => ({
      nextArrow: <SlideNextArrow />,
      prevArrow: <SlidePrevArrow />,
    }),
    [],
  );

  return (
    <Modal title="Product image" open={open} onClose={onClose} sectioned large>
      <SlideContainer>
        <React.Suspense fallback={<Spinner size="small" />}>
          <Carousel arrows {...settings}>
            {images.filter(Boolean).map((file, index) => (
              <ImagePreview file={file} key={`mockup-${index}`} />
            ))}
          </Carousel>
        </React.Suspense>
      </SlideContainer>
    </Modal>
  );
}

function ImagePreview({ file }) {
  const [loading, setLoading] = React.useState(false);
  const handleLoaded = React.useCallback(() => {
    setLoading(true);
  }, []);

  const imgUrl = getUrl(file);
  return (
    <div>
      <ImageContainer>
        {!loading && <Spinner size="small" />}
        <div className="media-wrapper">
          <img
            src={imgUrl}
            alt={""}
            style={{
              height: "auto",
              maxWidth: "760px",
            }}
            onLoad={handleLoaded}
            onError={handleLoaded}
          />
          {file.name && (
            <div className="media-meta mt-8">
              <div className="media-meta-name">{file.name}</div>
            </div>
          )}
        </div>
      </ImageContainer>
      {imgUrl && (
        <div className="media-meta-link">
          <React.Suspense fallback={<Spinner size="small" />}>
            <ViewOriginFile file={file} />
          </React.Suspense>
        </div>
      )}
    </div>
  );
}

function getUrl(file, getUrl = true) {
  if (objectInvalid(file)) return null;
  if (getUrl) return file.url || file.thumbnailUrl;
  return file.thumbnailUrl || file.url;
}

function getFiles(values, fn = (f) => f) {
  if (arrInvalid(values)) return [];

  return values.map(fn).filter(Boolean);
}

function mergeImages(images, originImages) {
  if (arrInvalid(images) && arrInvalid(originImages)) return [];

  const mainImages = getFiles(images);
  const newImages = getFiles(originImages, (f) => f.file);

  return Array.from(
    new Map(
      [...mainImages, ...newImages].filter((i) => i?.id).map((i) => [i.id, i]),
    ).values(),
  ).filter(Boolean);
}

const Wrapper = styled.div`
  .img-controls {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;

    img {
      border: 2px solid transparent;

      &.active {
        border-color: #3f4eae;
      }
    }
  }

  .modal-image {
    padding: 1rem;
    padding-left: 0;
    position: relative;

    .color-picker-wrap {
      position: absolute;
      top: -12rem;
      right: 0;

      .Polaris-ColorPicker__MainColor {
        height: 12rem;
        width: 12rem;
      }

      .Polaris-ColorPicker__HuePicker,
      .Polaris-ColorPicker__AlphaPicker {
        height: 12rem;
        width: 1.8rem;
      }
    }
  }

  .img-wrap {
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 15px -3px,
      rgba(0, 0, 0, 0.1) 0px 4px 6px 2px;
  }

  .Polaris-Badge {
    font-weight: 600;
  }
`;
