import { useMutation } from "@apollo/react-hooks";
import {
  ActionList,
  Button,
  Modal,
  Popover,
  TextContainer,
} from "@shopify/polaris";
import {
  CircleDisabledMajorMonotone,
  DeleteMajorMonotone,
  ManagedStoreMajorTwotone,
  MarkPaidMinor,
  RefreshMajorMonotone,
} from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { AppContext, useAppContext } from "../../context";
import { getParamByRole, handleError, isPME_TEAM } from "../../helper";
import history from "../../history";
import useToggle from "../../hooks/useToggle";
import { useToastContext } from "../shared/ToastContext";
import MarkStoreStatus from "./MarkStoreStatus";
import FetchOrdersById from "../tiktok/actions/FetchOrdersById";
import FetchOrders from "../tiktok/actions/FetchOrders";
import { PLATFORMS } from "../../variable";
import FetchProducts from "../tiktok/actions/FetchProducts";
import FetchProductsByIds from "../tiktok/actions/FetchProductsById";
import DeactivatePromotions from "../tiktok/actions/DeactivePromotions";

const Container = styled.div`
  button + button {
    margin-left: 0.75rem;
  }
`;

const DELETE_STORE = gql`
  mutation deleteStore($id: ID!) {
    deleteStore(id: $id)
  }
`;

export const ColActionPolaris = ({ store, isStoreManager, refetch }) => {
  const { setStore } = useContext(AppContext);
  const [showModal, toggleShowModal] = useToggle(false);
  const [action, setAction] = useState(null);
  const [open, toggleOpen] = useToggle(false);

  const { currentUser } = useAppContext();
  const param = getParamByRole(currentUser);
  const isPMETeam = isPME_TEAM(currentUser);

  const onAction = useCallback((act) => {
    setAction(act);
    toggleShowModal();
  }, []);

  const handleManage = useCallback(() => {
    setStore({ id: store.id, title: store.title });
    history.push(`/store-manager/products`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, store]);

  const isAddon = !!store.apiKey && !store.certificate;
  const isDeactivated = store?.status === "Deactivated";
  const isTiktok = store?.platform == PLATFORMS.Tiktok;
  const tiktokAction = isTiktok && !isAddon && isStoreManager;

  const actions = [
    (!isStoreManager || isPMETeam) && {
      content: "Manage",
      icon: ManagedStoreMajorTwotone,
      onAction: () => history.push(`/${param}/store/edit/${store.id}`),
    },
    // isStoreManager && {
    //   content: "Manage",
    //   icon: ManagedStoreMajorTwotone,
    //   onAction: handleManage,
    // },
    tiktokAction && {
      content: "Fetch All Orders",
      icon: RefreshMajorMonotone,
      onAction: () => onAction("fetchAll"),
    },
    tiktokAction && {
      content: "Fetch Orders by ID",
      icon: RefreshMajorMonotone,
      onAction: () => onAction("fetchOrderByIds"),
    },
    tiktokAction && {
      content: "Fetch All Products",
      icon: RefreshMajorMonotone,
      onAction: () => onAction("fetchAllProduct"),
    },
    tiktokAction && {
      content: "Fetch Products by ID",
      icon: RefreshMajorMonotone,
      onAction: () => onAction("fetchProductByIds"),
    },
    tiktokAction && {
      content: "Deactivate All Promotions",
      icon: CircleDisabledMajorMonotone,
      onAction: () => onAction("deactivatePromotions"),
    },
    {
      content: `Mark as ${isDeactivated ? "Connected" : "Deactivated"}`,
      icon: MarkPaidMinor,
      onAction: () => onAction("markStatus"),
    },
    {
      content: "Delete",
      icon: DeleteMajorMonotone,
      destructive: true,
      onAction: () => onAction("delete"),
    },
  ].filter(Boolean);

  const props = {
    open: showModal,
    onClose: toggleShowModal,
    storeId: store?.id,
    store: store,
    refetch,
    isDeactivated,
  };
  const actionsMarkup = {
    delete: <DeleteButton {...props} />,
    markStatus: <MarkStoreStatus {...props} />,
    fetchOrderByIds: <FetchOrdersById {...props} />,
    fetchAllProduct: <FetchProducts {...props} />,
    fetchProductByIds: <FetchProductsByIds {...props} />,
    fetchAll: <FetchOrders {...props} />,
    deactivatePromotions: <DeactivatePromotions {...props} />,
  };

  const activator = (
    <Button children="Actions" disclosure onClick={toggleOpen} />
  );

  return (
    <Container>
      <Popover activator={activator} onClose={toggleOpen} active={open}>
        <ActionList items={actions} />
      </Popover>
      {actionsMarkup[action]}
    </Container>
  );
};

function DeleteButton({ onClose, open, refetch, storeId }) {
  const { setNotify, toggleToast } = useToastContext();

  const timerRef = React.useRef(null);
  const [deleteStore, { loading }] = useMutation(DELETE_STORE, {
    onCompleted: () => {
      setNotify({ msg: "Delete store succes", err: false });
      onClose && onClose();
      if (refetch) {
        timerRef.current && clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          refetch();
        }, 1500);
      }
    },
    onError: (err) => {
      setNotify({ msg: handleError(err?.toString()), err: true });
    },
  });

  const handleSubmit = React.useCallback(() => {
    toggleToast && toggleToast();
    setNotify && setNotify({ msg: null, err: false });
    deleteStore({
      variables: { id: storeId },
    });
  }, [deleteStore, toggleToast, setNotify, storeId]);

  return (
    <Modal
      title={"Delete store"}
      open={open}
      onClose={onClose}
      primaryAction={{
        content: "Submit",
        loading: loading,
        onAction: handleSubmit,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>Are you sure to delete this store?</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}
