import { Autocomplete, Tag } from "@shopify/polaris";
import React from "react";

import {
  arrInvalid,
  getUniqueV2,
  handleError,
  reducerFn,
  sleep,
} from "../../../helper";
import { useCallbackRef } from "../../../hooks/useCallbackRef";
import { ComponentLabelPolaris } from "../../shared/ComponentLabelPolaris";
import { useTeamMember } from "../hooks";
import { TagContainer } from "../../shared/TagContainer";
import useTimeout from "../../../hooks/useTimeout";

function TeamMemberSelect({
  value,
  source,
  error,
  label,
  labelHidden,
  allowMultiple,
  onChange: onChangeProp = () => {},
  fragment = "id role user {id firstName lastName}",
  filter: propFilter = {},
  getValueImmediate, // get value immediate when selection
}) {
  // State
  const [state, setState] = React.useReducer(reducerFn, {
    options: [],
    selected: [],
    inputVal: "",
    selectedOptions: [],
    deselectedOptions: [],
  });

  // Hooks
  const {
    data,
    loading,
    error: errorQuery,
  } = useTeamMember(propFilter, false, fragment);

  const INPUT_ID = React.useMemo(() => {
    return "INPUT_COLLECTION_" + (Math.random() + "").slice(2, 16);
  }, []);

  const AUTOCOMPLETE_ID = React.useMemo(
    () => "AUTOCOMPLETE_COLLECTION_" + (Math.random() + "").slice(2, 16),
    [],
  );
  const COMBOBOX_SELECTOR = React.useMemo(
    () => `div[role="combobox"][aria-controls="${AUTOCOMPLETE_ID}"]`,
    [AUTOCOMPLETE_ID],
  );

  // Effect
  const inputChanged = React.useRef(null);
  React.useEffect(() => {
    async function getOptions() {
      const nodes = data || [];
      if (nodes?.length === 0) {
        setState({ options: [], deselectedOptions: [] });
        return;
      }

      let options = genOptions(nodes);
      if (!inputChanged.current) {
      //   const opts = genOptions(source);
      //   const mergedOpts = getUniqueV2("value", options, opts);
      //   const ids = genOptions(mergedOpts, ({ value }) => value);

      //   const optionsOther = await getGroupsMissing(ids, value, fragment);
      //   const optionsOtherFormat = genOptions(optionsOther);
      //   options = getUniqueV2("value", mergedOpts, optionsOtherFormat);
      }

      setState({ options, deselectedOptions: options });

      // Focus again when options change
      // When has search value + changed is `false`
      if (inputChanged.current != null && !changedRef.current) {
        const input = document.getElementById(INPUT_ID);
        if (input) {
          input.blur && input.blur();
          await sleep(0);
          input.focus && input.focus();
        }
      }
    }
    getOptions();
  }, [data, source, value, fragment]);

  const getSelectedOptions = React.useCallback(
    (selected, deselectedOptions) => {
      const fn = (item) => {
        return deselectedOptions.find(({ value }) => value === item);
      };
      return genOptions(selected, fn).filter(Boolean);
    },
    [],
  );

  React.useLayoutEffect(() => {
    if (arrInvalid(value) || arrInvalid(state.options)) return;

    const deselectedOptions = getUniqueV2(
      "value",
      state.options,
      state.deselectedOptions,
    );
    const selectedOptions = getSelectedOptions(value, deselectedOptions);
    const newState = {
      selected: value,
      deselectedOptions,
    };

    if (allowMultiple) {
      newState.selectedOptions = selectedOptions;
    } else {
      const [firstItem] = selectedOptions || [];
      const { label } = firstItem || {};
      newState.inputVal = label || "";
    }

    setState(newState);
  }, [value, state.options, getSelectedOptions, allowMultiple]);

  // Actions
  const onChange = useCallbackRef(onChangeProp);
  const changedRef = React.useRef(false);
  const handleSelection = React.useCallback(
    (selected) => {
      const selectedOptions = getSelectedOptions(
        selected,
        getUniqueV2("value", state.options, state.deselectedOptions),
      );
      const newState = {
        selected,
        selectedOptions,
      };

      const [firstItem] = selectedOptions || [];
      const { label } = firstItem || {};
      if (!allowMultiple) {
        newState.inputVal = label;
      }
      setState(newState);

      changedRef.current = true;

      if (getValueImmediate) {
        const args = getSelectedInfo(selected, selectedOptions, allowMultiple);
        onChange(...args);
      }
    },
    [
      allowMultiple,
      state.options,
      state.deselectedOptions,
      getSelectedOptions,
      getValueImmediate,
    ],
  );

  const [setDelay] = useTimeout();
  const handleInputChange = React.useCallback(
    (search) => {
      setState({ inputVal: search });
      inputChanged.current = !!search;

      if (!search) {
        setState({ options: state.deselectedOptions });
        return;
      }

      try {
        setDelay(() => {
          const reg = new RegExp(search, "gi");
          const newOpts = (state.deselectedOptions || []).filter((opt) => {
            return !!(opt.label || "").match(reg);
          });

          setState({ options: newOpts });
        });
      } catch (err) {
        console.error("err >", err);
      }
    },
    [state.deselectedOptions],
  );

  const handleRemove = React.useCallback(
    (value) => () => {
      const selected = filterItem(state.selected, (s) => s !== value);
      const selectedOptions = filterItem(
        state.selectedOptions,
        (item) => item.value !== value,
      );

      setState({ selected, selectedOptions });
      allowMultiple && onChange(selected, selectedOptions);
    },
    [onChange, allowMultiple, state.selectedOptions, state.selected],
  );

  React.useLayoutEffect(() => {
    if (getValueImmediate) return;
    const container = document.querySelector(COMBOBOX_SELECTOR);
    if (container && onChange) {
      function updateValue(e) {
        const expanded = JSON.parse(
          container.getAttribute("aria-expanded") || "",
        );
        if (!expanded && !container.contains(e.target) && changedRef.current) {
          const selectedOptions = state.selectedOptions;
          const selected = state.selected;
          const [firstItem] = selectedOptions || [];
          const { value } = firstItem || {};
          allowMultiple
            ? onChange(selected, selectedOptions)
            : onChange(value, firstItem);

          changedRef.current = false;
          inputChanged.current = null;
        }
      }
      document.addEventListener("click", updateValue);

      return () => {
        document.removeEventListener("click", updateValue);
      };
    }
  }, [state.selectedOptions, state.selected, getValueImmediate]);

  const handleClearButton = React.useCallback(() => {
    setState((p) => ({
      ...p,
      inputVal: "",
      selectedOptions: [],
      selected: [],
      options: p.deselectedOptions,
    }));
    onChange(null);
  }, [onChange]);

  // Markup
  const textField = (
    <Autocomplete.TextField
      value={state.inputVal}
      onChange={handleInputChange}
      placeholder="Search group"
      error={error}
      id={INPUT_ID}
      clearButton
      onClearButtonClick={handleClearButton}
    />
  );

  return (
    <React.Fragment>
      {labelHidden ? null : (
        <ComponentLabelPolaris label={label || "Member"} required />
      )}
      {errorQuery ? (
        <div>Error: {handleError(errorQuery?.toString())}</div>
      ) : (
        <>
          <Autocomplete
            options={state.options}
            selected={state.selected}
            onSelect={handleSelection}
            textField={textField}
            allowMultiple={allowMultiple}
            emptyState={<span>No items found!</span>}
            loading={loading}
            id={AUTOCOMPLETE_ID}
          />
          {allowMultiple ? (
            <TagContainer>
              {state.selectedOptions?.length > 0
                ? state.selectedOptions.map((opt) => (
                    <Tag
                      children={opt.label}
                      key={opt.value}
                      onRemove={handleRemove(opt.value)}
                    />
                  ))
                : null}
            </TagContainer>
          ) : null}
        </>
      )}
    </React.Fragment>
  );
}

/**
 * Utils
 */
function filterItem(arr, fn) {
  if (arrInvalid(arr)) return [];

  return arr.filter(fn);
}

export function genOptions(
  data,
  fn = (item) =>
    item?.user
      ? {
          value: item.user.id,
          label:
            [item.user.firstName, item.user.lastName]
              .filter(Boolean)
              .join(" ") + ` (${item.user.id || ""})`,
          data: item,
        }
      : null,
) {
  if (arrInvalid(data)) return [];

  return data.map(fn).filter(Boolean);
}

function getSelectedInfo(selected, selectedOptions, allowMultiple) {
  const [firstItem] = selectedOptions || [];
  const { value } = firstItem || {};
  return allowMultiple ? [selected, selectedOptions] : [value, firstItem];
}

export default TeamMemberSelect;
