import React, { Fragment, useCallback, useReducer } from "react";
import { Button } from "@shopify/polaris";

import useToggle from "../../../hooks/useToggle";
import QuickPush from "./actions/QuickPush";
import { reducerFn } from "../../../helper";
import BulkDeleteCrawlItem from "./actions/bulk-delete-crawl-item";

export const BulkAction = () => {
  // State
  const [open, toggleOpen] = useToggle(false);
  const [state, setState] = useReducer(reducerFn, {
    open: false,
    action: null,
  });

  const handleOpenAction = useCallback((act) => () => {
    setState({ open: true, action: act });
  }, []);

  const handleCloseAction = useCallback(() => {
    setState({ open: false, action: null });
  }, []);

  const actionObj = {
    delete: (
      <BulkDeleteCrawlItem open={state.open} onClose={handleCloseAction} />
    ),
  };

  const actionMarkup = actionObj[state.action];

  return (
    <Fragment>
      <Button children="Quick Push" onClick={toggleOpen} />
      <QuickPush onClose={toggleOpen} open={open} />

      <Button children="Delete" onClick={handleOpenAction("delete")} />
      {actionMarkup}
    </Fragment>
  );
};
