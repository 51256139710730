import { useQuery } from "@apollo/react-hooks";
import { DataTable, TextStyle } from "@shopify/polaris";
import { get } from "lodash";
import React from "react";
import styled from "styled-components";

import { GET_TASK_SUBMISSION_AND_FIELD_VALUE_FOR_TASK } from "../../../graphql/queries";
import {
  arrInvalid,
  elementContains,
  handleError,
  throttle,
} from "../../../helper";
import ElementScrollable from "../../shared/ElementScrollable";
import EmptyStatePolaris from "../../shared/EmptyStatePolaris";
import PaginationPolaris from "../../shared/PaginationPolaris";
import { PersonalizedInfoInner } from "../../shared/PersonalizedInfo";
import SelectRow from "../../shared/SelectRow";
import AttributesComp from "./AttributesComp";
import RequirementComp from "./RequirementComp";
import TableDataNeedReviewAction from "./table-data-need-review-action";
import TableDataNeedReviewBulkAction from "./table-data-need-review-bulk-action";
import TableDataNeedReviewDesign from "./table-data-need-review-design";
import TableDataNeedReviewMockup from "./table-data-need-review-mockup";
import SkeletonPagePolaris from "../../shared/SkeletonPagePolaris";

function TableDataNeedReview({
  data,
  refetch,
  filter,
  handlePaginationChange,
}) {
  const ids = getTaskId(data);

  const {
    data: dataSubmission,
    loading,
    error,
  } = useQuery(GET_TASK_SUBMISSION_AND_FIELD_VALUE_FOR_TASK, {
    variables: {
      taskIDs: ids,
    },
    skip: !ids || ids.length === 0,
  });

  const handleRefChange = React.useCallback((node) => {
    if (!node) return;

    addNodeEvent(node);
  }, []);

  const nodes = getDataSubmission(dataSubmission);
  const mapTasks = mapTaskFn(data);

  let { limit, offset } = filter;
  const total = get(data, "tasksV2.total", 0);
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;

  const aggregation = {
    page,
    totalPage,
    offset,
    limit,
    onChange: handlePaginationChange,
    total,
  };

  return (
    <>
      <Container ref={handleRefChange}>
        {loading ? (
          <SkeletonPagePolaris />
        ) : error ? (
          <div> Error: {handleError(error.toString())}</div>
        ) : (
          <SelectRow
            data={nodes}
            refetch={refetch}
            actionMarkup={<TableDataNeedReviewBulkAction />}
          >
            {({ checkAllMarkup, checkItemMarkup }) => {
              const rows = getRows(checkItemMarkup, nodes, mapTasks);
              if (!rows || arrInvalid(rows)) return <EmptyStatePolaris />;
              return (
                <ElementScrollable fixedColumn>
                  <DataTable
                    rows={rows}
                    columnContentTypes={[
                      ...Array.from({ length: 5 }).fill("text"),
                      "numeric",
                    ]}
                    headings={[
                      checkAllMarkup,
                      "ID",
                      "Design",
                      "Order Detail",
                      "Mockup",
                      "Actions",
                    ]}
                    verticalAlign="middle"
                    hideScrollIndicator
                  />
                </ElementScrollable>
              );
            }}
          </SelectRow>
        )}
      </Container>
      <PaginationContainer>
        <PaginationPolaris aggregation={aggregation} showTotal />
      </PaginationContainer>
    </>
  );
}

//
function getRows(checkItemMarkup, nodes, mapTasks) {
  const res = [];

  for (let node of nodes) {
    const { id, fieldValues } = node;

    const currentTask = mapTasks[id] || {};
    const { personalized, orderId, order, requirement } = currentTask;

    const item = [
      checkItemMarkup(id),
      <div className="id item">
        <span>{id}</span>
      </div>,
      <div className="design-wrap item">
        <TableDataNeedReviewDesign
          node={{
            ...node,
            orderId: orderId,
            requirement: requirement,
          }}
        />
      </div>,
      <div className="order-detail-wrap item">
        <div>
          <TextStyle variation="strong">Order ID: </TextStyle>
          <span>{order?.id}</span>
        </div>
        <div>
          <TextStyle variation="strong">Order Origin ID: </TextStyle>
          <span>{order?.originId}</span>
        </div>
        <PersonalizedInfoInner
          personalized={personalized}
          fieldValues={fieldValues}
        />
        <AttributesComp order={order} />
        <RequirementComp taskRequirement={requirement} />
      </div>,
      <div className="mockup-wrap item">
        <TableDataNeedReviewMockup order={order} />
      </div>,
      <div className="action-wrap">
        <TableDataNeedReviewAction task={currentTask} />
      </div>,
    ];

    res.push(item);
  }
  return res;
}

function getDataSubmission(data) {
  const nodes = get(data, "getTaskSubmissionAndFieldValueForTask") || [];

  return nodes.map((item) => ({ ...item, id: item.taskID }));
}

function getTaskNode(data) {
  return get(data, "tasksV2.nodes") || [];
}

function mapTaskFn(data) {
  const nodes = getTaskNode(data);
  const res = {};
  for (let node of nodes) {
    if (!node || !node.id) continue;
    res[node.id] = node;
  }

  return res;
}

function getTaskId(data) {
  const nodes = getTaskNode(data);
  return nodes.map((item) => item?.id).filter(Boolean);
}

function handleNodeScroll(node) {
  if (!node) return;
  throttle(() => {
    const coordinates = {
      scrollLeft: node.scrollLeft,
      scrollWidth: node.scrollWidth,
      offsetWidth: node.offsetWidth,
    };

    handleAddCSSCls(coordinates);
  });
}

function addNodeEvent(node) {
  node.addEventListener("scroll", handleNodeScroll, {
    capture: false,
    passive: true,
  });
}

function handleAddCSSCls(coordinates) {
  let { scrollLeft, scrollWidth, offsetWidth } = coordinates;
  let addClass = offsetWidth + scrollLeft < scrollWidth;

  let domChilds = document.querySelectorAll(".actions_wrap");
  let domParents = document.querySelectorAll(".Polaris-DataTable__Cell");

  for (let domParent of domParents) {
    for (let domChild of domChilds) {
      if (elementContains(domParent, domChild)) {
        if (addClass) {
          domParent.classList.add("custom-cell");
        } else {
          domParent.classList.remove("custom-cell");
        }
      }
    }
  }
}

const Container = styled.div`
  .order-detail-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .mockup-wrap {
    width: 500px;

    .modal-image {
      max-width: 400px;
    }
  }
`;

const PaginationContainer = styled.div`
  padding: 1.6rem;
  overflow-x: scroll;
  background: var(--p-surface-subdued, #f4f6f8);
`;

export default TableDataNeedReview;
